import { Config, Type } from './config';

export const environment = {
  version: '258480b',
  production: true,
  name: 'Live',
  api: 'https://kong-internal-api.modalku.co.id/internal',
  base_website_url: 'https://commandcenter.modalku.co.id',
  Base_api_url: 'https://kong-internal-api.modalku.co.id',
  Auth_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/auth',
  Bond_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/v1/esbn',
  // Deprecated: use External_Endpoint instead.
  CTOS_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/ext',
  // Deprecated: use External_Endpoint instead.
  Questnet_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/ext',
  External_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/ext',
  FA_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/v2/fa',
  Parsing_service_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/dps',
  Document_EndPoint_V3: 'https://kong-internal-api.modalku.co.id/internal/api/docs_management/v3',
  Finops_Serverless_Workflows_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/fsw',
  DocGen_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/dg',
  Finance_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/fs',
  Finance_Endpoint_V2: 'https://kong-internal-api.modalku.co.id/internal/api/fs/v2',
  Finance_Pairing_Endpoint:
    'https://kong-internal-api.modalku.co.id/internal/api/finance_pairing/v1',
  Member_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/ms',
  Member_EndPoint_V2: 'https://kong-internal-api.modalku.co.id/internal/api/v2/members',
  Member_EndPoint_V3: 'https://kong-internal-api.modalku.co.id/internal/api/v3/members',
  SOA_EndPoint_V2: 'https://kong-internal-api.modalku.co.id/internal/api/v2/soa-reports',
  Loan_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/ln',
  LookUpNodeJS_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/lu/p',
  Loyalty_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/v1/loyalty',
  SMS_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/sms',
  Scheduler_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/sc',
  Logout_URI: 'https://kong-internal-api.modalku.co.id/internal/api/logout?redirect=',
  Logout_Page_URL: 'https://kong-internal-api.modalku.co.id/internal/login/logout.html?home=',
  Login_URI: 'https://kong-internal-api.modalku.co.id/internal/login/?redirect_uri=',
  User_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api',
  Notification_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/notification',
  Email_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/email',
  Staff_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/ss',
  Kyc_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/kyc/v1',
  CustomModule_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/cm',
  MicroLoan_Endpoint: 'https://microloan.modalku.co.id',
  MicroLoan_Docs_Gen_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/docs_gen',
  MicroLoan_Internal_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/ml',
  Underwriting_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/credit',
  Credit_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/credit/v2',
  v2_url: 'https://kong-internal-api.modalku.co.id/internal/api/v2',
  Collection_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/v1/loan-collections',
  Facility_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/facility',
  Investor_Channelling_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/ics',
  Facility_Endpoint_V2: 'https://kong-internal-api.modalku.co.id/internal/api/facility/v2',
  Collateral_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/cls',
  Dispatch_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/dispatch',
  ELO_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/elo_rating',
  ELO_Rating_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/credit/v2/elo',
  Paylater_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/paylater',
  Partnership_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/partners',
  myTukar_account_Id: 819726,
  carlistbid_account_id: 833585,
  MUV_account_Id: 819776,
  carsome_account_Id: 1068468,
  ezauto_account_Id: 1207675,
  launch_darkly_client_id: '5da5726df87c9308b76af5e6',
  Bolt_Partner_EndPoint: 'https://kong-internal-api.modalku.co.id/internal/api/bpp',
  Borrower_Backend_Service_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/bbs',
  Ekyc_Esign_Service_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/ees',
  Bank_Integration_Service_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/bis',
  scfs: 'https://old-scfs.fundingasiagroup.com',
  bowIDOrigin: 'https://apply.modalku.co.id',
  hideNonMKItem: true, // For ID Audit
  hideNonFSItem: false, // For ID Audit
  Credit_Card_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/ccs',
  Business_Account_Endpoint: 'https://kong-internal-api.modalku.co.id/internal/api/bas',
  download_soa: `https://kong-internal-api.modalku.co.id/internal/api/dg/v1/document`,
  type: new Config(Type.PROD),
  template_id: '9880c06e-a8a0-40b6-a1f5-a00dfeeef0a6',
  sheet_name: 'Sheet1',
  qlmProductIds: [16, 13, 193],
  enablePrivy: false,
  productIds: {
    islamicMicrofinancingMY: 195,
    islamicEcommerceMY: 196,
    boltMyCgcd: 235,
  },
  loanLevelSOA: {
    SG: {
      fs_capital: {
        template_id: 'cb1cf318-a924-4c4e-be75-2734cf1f3730',
      },
      fspl: {
        template_id: 'cb1cf318-a924-4c4e-be75-2734cf1f3730',
      },
    },
  },
  Appsmith_Scheduler_Url:
    'https://appsmith.modalku.co.id/app/scheduler-tool/create-new-schedule-6757f6bd460fc550342834a8',
  appsmithCollateralUrl:
    'https://appsmith.modalku.co.id/app/collateral-management/home-670e8caa3f8de3782f6815d4',
  masterCustomerData: {
    individualsUrl: '',
    companiesUrl: '',
  },
  datadogConfiguration: {
    applicationId: 'c1d00e6a-385b-4ec3-b623-c2924566427c',
    clientToken: 'pubc83c201656cc2c04546bb59e396d08f2',
  },
  sentryDsn:
    'https://2b00313f1a03c94392d4ef99c7cf1fd8@o103441.ingest.us.sentry.io/4507887847931904',
};
